import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Preview from "../components/preview";
import "../components/css/styles.css";

const Popular = ({ n }) => {
  // Fetch data using Gatsby's useStaticQuery hook
  // allStrapiArticle(sort: {fields: published_at, order: DESC}, limit: 100) { // You can limit the query on all articles if you want
  const data = useStaticQuery(graphql`
    query PopularPosts {
      allStrapiArticle(sort: {fields: published_at, order: DESC}, limit: 200) {
        edges {
          node {
            id
            title
            authors {
              id
              name
            }
            categories {
              id
              title
            }
            image {
              publicURL
            }
            published_at
            fields {
              slug
            }
          }
        }
      }
      allPageViews(sort: {order: DESC, fields: totalCount}, limit: 50) {
        edges {
          node {
            path
            totalCount
          }
        }
      }
    }
  `);

  const allArticles = data.allStrapiArticle.edges;
  const popularArticles = data.allPageViews.edges;

  // Debugging logs to inspect the data
  //console.log('All Articles:', allArticles);
  //console.log('Popular Articles:', popularArticles);

  const recentResults = useMemo(() => {
    function chooseTop(allArticles, popularArticles, n) {
      const results = [];
      for (const a of popularArticles) {
        // Normalize the path by removing leading slashes
        //const pathWithoutLeadingSlash = a.node.path.replace(/^\/+/, "");
        const pathWithoutLeadingSlash = a.node.path;
        
        //console.log("Checking path:", pathWithoutLeadingSlash);

        const popularArticle = allArticles.find(b => {
          //console.log('Checking article slug:', b.node.fields.slug);
          return b.node.fields.slug === pathWithoutLeadingSlash;
        });
        
        if (popularArticle == null) {
          //console.log(`No match found for path: ${pathWithoutLeadingSlash}`);
          continue;
        } else {
          //console.log("Matched article:", popularArticle.node.title);
          results.push({
            ...popularArticle.node,
          });
        }

        if (results.length >= n) {
          break;
        }
      }
      return results;
    }

    return chooseTop(allArticles, popularArticles, n);
  }, [data, n]);

  return (
    <ul>
      {recentResults.map(document => (
        <li key={document.id} className="mt-4 pb-4 border-b border-bottom-gray">
          <Preview article={document} format="small" />
        </li>
      ))}
    </ul>
  );
};

export default Popular;

/*import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Preview from "../components/preview";
import "../components/css/styles.css";

const Popular = ({ n }) => {
  // Fetch data using Gatsby's useStaticQuery hook
  const data = useStaticQuery(graphql`
    query PopularPosts {
      allStrapiArticle(sort: {fields: published_at, order: DESC}, limit: 100) {
        edges {
          node {
            id
            title
            authors {
              id
              name
            }
            categories {
              id
              title
            }
            image {
              publicURL
            }
            published_at
            fields {
              slug
            }
          }
        }
      }
      allPageViews(sort: {order: DESC, fields: totalCount}, limit: 50) {
        edges {
          node {
            path
            totalCount
          }
        }
      }
    }
  `);

  // Memoize the result of chooseTop to avoid recalculating on every render
  const allArticles = data.allStrapiArticle.edges;
  const popularArticles = data.allPageViews.edges;
  //console.log('All Articles:', allArticles);
  //console.log('Popular Articles:', popularArticles);
  const recentResults = useMemo(() => {
    function chooseTop(allArticles, popularArticles, n) {
      const results = [];
      for (const a of popularArticles) {
        //const popularArticle = allArticles.find(b => b.node.fields.slug === a.node.path);
        // Normalize the path by removing leading slashes
        const pathWithoutLeadingSlash = a.node.path.replace(/^\/+/, "");
        const popularArticle = allArticles.find(b => b.node.fields.slug === pathWithoutLeadingSlash);
    
	if (popularArticle == null) {
          continue;
        } else {
          results.push({
            ...popularArticle.node,
          });
        }
        if (results.length >= n) {
          break;
        }
      }
      return results;
    }

    return chooseTop(data.allStrapiArticle.edges, data.allPageViews.edges, n);
  }, [data, n]);

  return (
    <ul>
      {recentResults.map(document => (
        <li key={document.id} className="mt-4 pb-4 border-b border-bottom-gray">
          <Preview article={document} format="small" />
        </li>
      ))}
    </ul>
  );
};

export default Popular;*/

/*import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Preview from "../components/preview"

import "../components/css/styles.css"

const Popular = ({ n }) => {
    const data = useStaticQuery(graphql`
    query PopularPosts {
        allStrapiArticle(sort: {fields: published_at, order: DESC}, limit: 100) {
          edges {
            node {
                id
                title
                authors {
                    id
                    name
                }
                categories {
                    id
                    title
                }
                image {
                    publicURL
                }
                published_at
                fields {
                    slug
                }
            }
          }
        }
        allPageViews(sort: {order: DESC, fields: totalCount}, limit: 50) {
          edges {
            node {
              path
              totalCount
            }
          }
        }
      }
    `)

    function chooseTop(allArticles, popularArticles, n) {
        const results = [];
        for (const a of popularArticles) {
            const popularArticle = allArticles.find(b => b.node.fields.slug === a.node.path);
            if (popularArticle == null) {
                continue;
            } else {
                results.push({
                    ...popularArticle.node,
                });
            }
            if (results.length >= n) {
                break;
            }
        };
        return results;
    }

    const recentResults = chooseTop(data.allStrapiArticle.edges, data.allPageViews.edges, n);

    return (
        <ul>
            {recentResults.map(document => (
                <li key={document.id} className="mt-4 pb-4 border-b border-bottom-gray">
                    <Preview article={document} format="small" />
                </li>
            ))}
        </ul>
    )
}

export default Popular*/
/*const Popular = ({ n }) => {
    const data = useStaticQuery(graphql`
    query RecentPosts {
        allStrapiArticle(sort: {fields: published_at, order: DESC}, limit: 100) {
          edges {
            node {
                id
                title
                authors {
                    id
                    name
                }
                categories {
                    id
                    title
                }
                image {
                    publicURL
                }
                published_at
                fields {
                    slug
                }
            }
          }
        }
      }
    `)

    function getRecentArticles(allArticles, n) {
        return allArticles.slice(0, n).map(article => ({
            ...article.node,
        }));
    }

    const recentArticles = getRecentArticles(data.allStrapiArticle.edges, n);

    return (
        <div>
            {recentArticles.map(article => (
                <Preview key={article.id} article={article} />
            ))}
        </div>
    );
}

export default Popular;*/

